"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.BulkFilterUpdateContent = BulkFilterUpdateContent;
exports.BulkFilterUpdate = BulkFilterUpdate;
var react_1 = __importStar(require("react"));
var components_1 = require("@shared/components");
var scroll_1 = require("@shared/components/blocks/contexts/scroll");
var BulkUpdateProvider_1 = require("@shared/components/filter/state/BulkUpdateProvider");
var toast_1 = require("@shared/components/toast");
var BatchContext_1 = require("@shared/scenes/cards/entityBulkActions/BatchCreateContext/BatchContext");
var ComponentFocus_1 = require("@shared/scenes/cards/form/components/ComponentFocus");
var BulkFilterUpdateForm_1 = require("@shared/scenes/entity/edit/components/BulkFilterUpdateForm");
var useBulkUpdate_1 = require("@shared/scenes/entity/edit/hooks/useBulkUpdate");
var constants_1 = require("@shared/styles/constants");
var i18n_1 = require("@shared/util/i18n");
var navigation_1 = require("@shared/util/navigation");
var modalTitle = (0, i18n_1.i18n)('card.actions.share.bulkUpdateCards.title');
function BulkFilterUpdateContent(_a) {
    var workflowTemplate = _a.workflowTemplate;
    var _b = (0, BulkUpdateProvider_1.useBulkUpdateState)(), showBulkFilterUpdateForm = _b.showBulkFilterUpdateForm, setShowBulkFilterUpdateForm = _b.setShowBulkFilterUpdateForm, setShowBulkFilterUpdate = _b.setShowBulkFilterUpdate, controlsState = _b.controlsState, totalCardCount = _b.totalCardCount;
    var toast = (0, react_1.useContext)(toast_1.ToastContext);
    var navigateToBulkUpdateQueue = (0, navigation_1.useNavigateToRoute)('BulkUpdateQueue');
    var workflowTemplateId = workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.id;
    var _c = (0, useBulkUpdate_1.useBulkUpdate)({
        workflowTemplate: workflowTemplate,
        controlsState: controlsState,
    }), fields = _c.fields, updateField = _c.updateField, isDirty = _c.isDirty, onPressSave = _c.onPressSave, isLoading = _c.isLoading, availableComponents = _c.availableComponents, selectedComponents = _c.selectedComponents, onSelectComponent = _c.onSelectComponent, onRemoveComponent = _c.onRemoveComponent, errors = _c.errors, status = _c.status;
    var isSaveSuccessful = status === 'success';
    var successMessage = (0, i18n_1.i18n)('card.actions.share.bulkUpdateCards.toast.success', {
        count: totalCardCount,
    });
    (0, react_1.useEffect)(function () {
        // close modal if save was successful
        if (isSaveSuccessful) {
            toast.show({
                message: successMessage,
                layout: 'long',
                showClose: false,
                actions: [
                    {
                        label: (0, i18n_1.i18n)('card.actions.share.bulkUpdateCards.toast.viewUpdates'),
                        onPress: navigateToBulkUpdateQueue,
                    },
                ],
            });
            setShowBulkFilterUpdate(false);
            setShowBulkFilterUpdateForm(false);
        }
    }, [
        isSaveSuccessful,
        successMessage,
        toast,
        setShowBulkFilterUpdate,
        setShowBulkFilterUpdateForm,
        navigateToBulkUpdateQueue,
    ]);
    if (!showBulkFilterUpdateForm) {
        return null;
    }
    return (react_1.default.createElement(components_1.MBModalOrMobile, { desktopModalWidth: constants_1.Constants.pageModalWidth, desktopModalHeight: 'calc(95vh - 76px)' /* height of bottom button */, title: modalTitle, paddingOnWeb: "none", onBack: function () { return setShowBulkFilterUpdateForm(false); }, desktopModalRightButton: react_1.default.createElement(RightContent, { isDirty: isDirty, onPressSave: onPressSave, isLoading: isLoading, count: totalCardCount }) },
        react_1.default.createElement(ComponentFocus_1.ComponentFocusProvider, null,
            react_1.default.createElement(scroll_1.CardBlockScrollProvider, null,
                react_1.default.createElement(BatchContext_1.BatchEditContextProvider, null,
                    react_1.default.createElement(BulkFilterUpdateForm_1.BulkFilterUpdateForm, { fields: fields, updateField: updateField, availableComponents: availableComponents, selectedComponents: selectedComponents, errors: errors, workflowTemplateId: workflowTemplateId, onSelectComponent: onSelectComponent, onRemoveComponent: onRemoveComponent }))))));
}
function BulkFilterUpdate(_a) {
    var workflowTemplate = _a.workflowTemplate;
    var showBulkFilterUpdateForm = (0, BulkUpdateProvider_1.useBulkUpdateState)().showBulkFilterUpdateForm;
    if (!showBulkFilterUpdateForm) {
        return null;
    }
    return react_1.default.createElement(BulkFilterUpdateContent, { workflowTemplate: workflowTemplate });
}
function RightContent(_a) {
    var isDirty = _a.isDirty, onPressSave = _a.onPressSave, isLoading = _a.isLoading, count = _a.count;
    return (react_1.default.createElement(components_1.MBView, { row: true, paddingRight: 1 },
        react_1.default.createElement(SaveButton, { isDirty: isDirty, onPressSave: onPressSave, isLoading: isLoading, count: count })));
}
function SaveButton(_a) {
    var isDirty = _a.isDirty, onPressSave = _a.onPressSave, isLoading = _a.isLoading, count = _a.count;
    var showSaveButton = isDirty;
    if (!showSaveButton) {
        return null;
    }
    return (react_1.default.createElement(components_1.MBHeaderButton, { title: (0, i18n_1.i18n)('card.actions.share.bulkUpdateCards.form.saveButton', { count: count }), onPress: onPressSave, loading: isLoading }));
}
