"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ActivityFeedProvider = ActivityFeedProvider;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_redux_1 = require("react-redux");
var api_1 = require("@shared/api");
var hooks_1 = require("@shared/util/hooks");
var context_1 = require("./context");
function normalizeItems(items) {
    return (0, lodash_1.sortBy)((0, lodash_1.uniqBy)(items, 'id'), 'createdAt').reverse();
}
function ActivityFeedProvider(_a) {
    var _this = this;
    var children = _a.children;
    var _b = (0, react_1.useState)(), items = _b[0], setItems = _b[1];
    var _c = (0, react_1.useState)(0), unread = _c[0], setUnread = _c[1];
    var markAllAsRead = (0, react_1.useCallback)(function () { return setUnread(0); }, []);
    var _d = (0, react_1.useState)(), nextCursor = _d[0], setNextCursor = _d[1];
    var businessId = (0, react_redux_1.useSelector)(function (state) { return state.appState.currentBusinessId; });
    var apolloClient = (0, client_1.useApolloClient)();
    var hasItems = !(0, lodash_1.isNil)(items);
    var loadMore = (0, react_1.useCallback)(function () { return __awaiter(_this, void 0, void 0, function () {
        var res, newItems_1, err_1;
        var _a, _b, _c, _d, _e;
        return __generator(this, function (_f) {
            switch (_f.label) {
                case 0:
                    if (hasItems && (0, lodash_1.isNil)(nextCursor)) {
                        return [2 /*return*/];
                    }
                    if ((0, lodash_1.isNil)(businessId)) {
                        return [2 /*return*/];
                    }
                    _f.label = 1;
                case 1:
                    _f.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, apolloClient.query({
                            query: api_1.Queries.activity.listActivityFeedItems,
                            variables: { input: { limit: 10, cursor: nextCursor } },
                        })];
                case 2:
                    res = _f.sent();
                    newItems_1 = (_b = (_a = res.data) === null || _a === void 0 ? void 0 : _a.listActivityFeedItems.items) !== null && _b !== void 0 ? _b : [];
                    setItems(function (prev) {
                        if (prev === void 0) { prev = []; }
                        return normalizeItems(__spreadArray(__spreadArray([], prev, true), newItems_1, true));
                    });
                    setNextCursor((_e = (_d = (_c = res.data) === null || _c === void 0 ? void 0 : _c.listActivityFeedItems) === null || _d === void 0 ? void 0 : _d.nextCursor) !== null && _e !== void 0 ? _e : undefined);
                    return [3 /*break*/, 4];
                case 3:
                    err_1 = _f.sent();
                    if (err_1 instanceof Error && err_1.message === 'User/business relationship invalid') {
                        // note: this can happen when using org login on the user selection screen
                        console.error('User not logged in yet.');
                    }
                    else {
                        throw err_1;
                    }
                    return [3 /*break*/, 4];
                case 4: return [2 /*return*/];
            }
        });
    }); }, [apolloClient, nextCursor, hasItems, businessId]);
    (0, react_1.useEffect)(function () {
        setItems(undefined);
        setNextCursor(undefined);
        setUnread(0);
    }, [businessId]);
    var prevItems = (0, hooks_1.usePrevious)(items);
    (0, react_1.useEffect)(function () {
        if (!items && !!prevItems) {
            loadMore();
        }
    }, [items, prevItems, loadMore]);
    (0, react_1.useEffect)(function () {
        loadMore();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    (0, client_1.useSubscription)(api_1.Subscriptions.activity.activityFeedUpdated, {
        onData: function (_a) {
            var _b;
            var data = _a.data;
            var newItem = (_b = data === null || data === void 0 ? void 0 : data.data) === null || _b === void 0 ? void 0 : _b.activityFeedUpdated;
            if (!(0, lodash_1.isNil)(newItem)) {
                setItems(function (prev) {
                    if (prev === void 0) { prev = []; }
                    // prevent duplicates from affecting unread badge
                    if (prev.some(function (item) { return item.id === newItem.id; })) {
                        return prev;
                    }
                    setUnread(function (prevUnread) { return prevUnread + 1; });
                    return normalizeItems(__spreadArray([newItem], prev, true));
                });
            }
        },
    });
    var value = (0, react_1.useMemo)(function () { return ({
        items: items !== null && items !== void 0 ? items : [],
        loadMore: loadMore,
        unread: unread,
        markAllAsRead: markAllAsRead,
    }); }, [items, loadMore, unread, markAllAsRead]);
    return react_1.default.createElement(context_1.ActivityFeedContext.Provider, { value: value }, children);
}
