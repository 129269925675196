"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.MBOverlay = void 0;
// @ts-strict-ignore
var React = __importStar(require("react"));
var ReactDOM = __importStar(require("react-dom"));
var components_1 = require("@shared/components");
var sentry_1 = require("@shared/util/sentry");
/*
    This helper class makes it possible to overlay any React component
    anywhere in the screen from web. Basically a new React DOM is created
    inside div#shared-components-tooltip
*/
exports.MBOverlay = {
    container: function () {
        return getOrCreateContainer('shared-components-tooltip');
    },
    show: function (_a) {
        var parent = _a.parent, closeOnBackdropPress = _a.closeOnBackdropPress, onClose = _a.onClose, onBackdropPress = _a.onBackdropPress, _b = _a.position, position = _b === void 0 ? defaultPosition : _b, Component = _a.component;
        // 1. Get the HTML node to render the action sheet in. This element is
        //    defined in public/index.html.ejs
        var container = exports.MBOverlay.container();
        // 2. Get the parent's DOM node and calculate it's absolute position in the screen
        // Note: we need to take the current window's scroll position into account
        // (especially for Storybook): https://stackoverflow.com/a/27129257/9117995
        var parentNode = (function () {
            try {
                return ReactDOM.findDOMNode(parent);
            }
            catch (error) {
                // ReactDOM.findDOMNode causes the following error in Sentry:
                // https://sentry.io/share/issue/b13523def7ba4db1accbae1bd70fb766
                // We do not
                console.warn('[MBOverlay] failed to get parent DOM node', error.message);
                sentry_1.MBSentry.exception({ error: error });
            }
        })();
        if (!parentNode) {
            return;
        }
        var parentNodeRect = parentNode.getBoundingClientRect();
        var parentNodeRectWithScrollOffset = {
            top: parentNodeRect.top + window.scrollY,
            left: parentNodeRect.left + window.scrollX,
            right: parentNodeRect.right - window.scrollX,
            bottom: parentNodeRect.bottom - window.scrollY,
            width: parentNodeRect.width,
            height: parentNodeRect.height,
        };
        // 3. Get the style for the content to be overlaid (read more docs above the function)
        var positionStyle = position(parentNodeRectWithScrollOffset);
        // Close the action sheet by simply removing the React component mounted in the container
        // TODO(fant): make the component fade out so that's not so disruptive
        function close() {
            ReactDOM.unmountComponentAtNode(container);
            onClose === null || onClose === void 0 ? void 0 : onClose();
        }
        function handleBackdropPress() {
            close();
            onBackdropPress === null || onBackdropPress === void 0 ? void 0 : onBackdropPress();
        }
        var Backdrop = function () {
            var _a = React.useState(false), visible = _a[0], setVisible = _a[1];
            // Why set timeout? Otherwise, if closeOnBackdropPress is set, we will
            // render "backdrop". When pressed it will close the overlay. On mobile
            // web, backdrop's div's onClick is triggered from the click that made
            // the MBOverlay.show call, which makes the overlay disappear right away.
            // By showing the overlay in the next run loop, we avoid this behavior.
            React.useEffect(function () {
                var timer = setTimeout(function () { return setVisible(true); }, 500);
                return function () { return clearTimeout(timer); };
            }, []);
            if (!visible) {
                return null;
            }
            return (React.createElement("div", { onClick: handleBackdropPress, style: {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                } }));
        };
        var component = (
        // Make sure this zIndex is higher than webReactModalOverlay.zIndex
        React.createElement(components_1.MBView, { absolute: true, style: { zIndex: 10000 } },
            !!closeOnBackdropPress && React.createElement(Backdrop, null),
            React.createElement(components_1.MBView, { style: positionStyle },
                React.createElement(Backdrop, null),
                React.createElement(Component, { close: close }))));
        // Note: our current version of react-dom doesn't have ReactDOM.createPortal
        // which is the preferred way of doing this.
        // https://reactjs.org/docs/react-dom.html#createportal
        ReactDOM.unstable_renderSubtreeIntoContainer(parent, component, container);
    },
    /*
  
      */
    hide: function () {
        var container = exports.MBOverlay.container();
        ReactDOM.unmountComponentAtNode(container);
    },
};
/*
    Get the container we will place the tooltip in. This should be declared
    in index.html. However, if it doesn't exist (eg in Storybook), we create
    the container element-

    Inspired by: https://stackoverflow.com/a/26083136/9117995
*/
function getOrCreateContainer(id) {
    var container = document.getElementById(id);
    if (!!container) {
        return container;
    }
    var newContainer = document.createElement('div');
    newContainer.setAttribute('id', id);
    document.getElementsByTagName('body')[0].appendChild(newContainer);
    return newContainer;
}
function defaultPosition(_a) {
    var top = _a.top, left = _a.left, right = _a.right, bottom = _a.bottom;
    return {
        top: top,
        left: left,
        position: 'absolute',
        right: window.innerWidth - right,
        bottom: window.innerHeight - bottom,
    };
}
