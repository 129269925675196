"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useListFileOperations = useListFileOperations;
var client_1 = require("@apollo/client");
var lodash_1 = require("lodash");
var react_1 = require("react");
var api_1 = require("@shared/api");
var PAGE_SIZE = 10;
function useListFileOperations() {
    var _a = (0, client_1.useQuery)(api_1.Queries.fileOperation.listFileOperations, { variables: { input: { limit: PAGE_SIZE, offset: 0 } }, fetchPolicy: 'network-only' }), data = _a.data, loading = _a.loading, error = _a.error, fetchMore = _a.fetchMore, refetch = _a.refetch;
    var hasMore = (0, react_1.useMemo)(function () {
        var _a, _b;
        return (_b = (_a = data === null || data === void 0 ? void 0 : data.listFileOperations) === null || _a === void 0 ? void 0 : _a.hasMore) !== null && _b !== void 0 ? _b : false;
    }, [data === null || data === void 0 ? void 0 : data.listFileOperations]);
    var fileOperations = (0, react_1.useMemo)(function () {
        var _a, _b;
        return (_b = (_a = data === null || data === void 0 ? void 0 : data.listFileOperations) === null || _a === void 0 ? void 0 : _a.items) !== null && _b !== void 0 ? _b : [];
    }, [data === null || data === void 0 ? void 0 : data.listFileOperations]);
    var loadMore = (0, react_1.useCallback)(function () {
        if (!(0, lodash_1.isEmpty)(fileOperations) && hasMore) {
            fetchMore({
                variables: {
                    input: { limit: PAGE_SIZE, offset: fileOperations.length },
                },
            });
        }
    }, [fileOperations, fetchMore, hasMore]);
    return (0, react_1.useMemo)(function () { return ({
        fileOperations: fileOperations,
        hasMore: hasMore,
        loadMore: loadMore,
        loading: loading,
        error: error,
        refetch: refetch,
    }); }, [fileOperations, hasMore, loadMore, loading, error, refetch]);
}
