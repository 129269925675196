"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useNavigateBackCardUpdate = useNavigateBackCardUpdate;
exports.MBCardUpdateForm = MBCardUpdateForm;
var bluebird_1 = require("bluebird");
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var react_native_1 = require("react-native");
var components_1 = require("@shared/components");
var channel_1 = require("@shared/components/blocks/contexts/channel");
var viewTemplate_1 = require("@shared/components/blocks/contexts/viewTemplate");
var correctMissingBlockData_1 = require("@shared/components/blocks/utils/correctMissingBlockData");
var templateNotFound_1 = require("@shared/scenes/cards/components/templateNotFound");
var components_2 = require("@shared/scenes/cards/form/components");
var hooks_1 = require("@shared/scenes/cards/form/hooks");
var hooks_2 = require("@shared/scenes/cards/hooks");
var hooks_3 = require("@shared/scenes/workflows/hooks");
var hooks_4 = require("@shared/util/hooks");
var navigation_1 = require("@shared/util/navigation");
var BatchContext_1 = require("../../entityBulkActions/BatchCreateContext/BatchContext");
function useNavigateBackCardUpdate(returnChannelSlug, returnCardId) {
    var navigator = (0, navigation_1.useNavigator)();
    return (0, react_1.useCallback)(function () {
        if ((0, lodash_1.isNil)(returnChannelSlug)) {
            navigator.goBack();
        }
        else {
            navigator.replace((0, lodash_1.isNil)(returnCardId) ? 'CardCreate' : 'CardUpdate', {
                channelSlug: returnChannelSlug,
                cardId: returnCardId,
                restoreSavedData: true,
            });
        }
    }, [navigator, returnCardId, returnChannelSlug]);
}
function CardUpdateForm(_a) {
    var _this = this;
    var cardId = _a.cardId, channelSlug = _a.channelSlug, templateSlug = _a.templateSlug, fieldOverrides = _a.fieldOverrides, formDirty = _a.formDirty, returnTo = _a.returnTo, restoreSavedData = _a.restoreSavedData, onDirty = _a.onDirty, onSubmitFormButtonCallback = _a.onSubmitFormButtonCallback;
    var card = (0, hooks_2.useCard)(cardId).card;
    var submitting = (0, react_1.useRef)(false);
    var channel = (0, hooks_4.useChannel)({ channelSlug: channelSlug });
    var cardDefinition = (0, hooks_2.useCardDefinition)(channel === null || channel === void 0 ? void 0 : channel.id, card === null || card === void 0 ? void 0 : card.workflowTemplateId, card === null || card === void 0 ? void 0 : card.cardDefinitionId).cardDefinition;
    var workflowTemplate = (0, hooks_3.useWorkflowTemplateWithCardDefinition)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId).workflowTemplate;
    var _b = (0, hooks_2.useCardTemplate)({
        channelSlug: channelSlug,
        templateSlug: templateSlug,
        context: (0, lodash_1.isNil)(templateSlug) ? 'edit' : null,
        cardDefinition: cardDefinition,
        card: card,
    }), template = _b.template, loading = _b.loading;
    var blocks = (0, react_1.useMemo)(function () { return (0, correctMissingBlockData_1.correctMissingBlockData)(template === null || template === void 0 ? void 0 : template.cardBlocks, workflowTemplate === null || workflowTemplate === void 0 ? void 0 : workflowTemplate.components); }, [template, workflowTemplate]);
    var viewTemplate = (0, hooks_3.useViewTemplate)(channel === null || channel === void 0 ? void 0 : channel.workflowTemplateId, template === null || template === void 0 ? void 0 : template.slug).viewTemplate;
    var navigator = (0, navigation_1.useNavigator)();
    var updateCard = (0, hooks_1.useUpdateCard)();
    var navigateBack = useNavigateBackCardUpdate(returnTo === null || returnTo === void 0 ? void 0 : returnTo.channelSlug, returnTo === null || returnTo === void 0 ? void 0 : returnTo.cardId);
    var handleSubmit = (0, react_1.useCallback)(function (fields) { return __awaiter(_this, void 0, void 0, function () {
        var updatedCard;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    submitting.current = true;
                    return [4 /*yield*/, updateCard(card, fields)];
                case 1:
                    updatedCard = _a.sent();
                    submitting.current = false;
                    // // note: updatedCard is null on mutation error
                    if (updatedCard) {
                        navigateBack();
                    }
                    if (!(updatedCard && updatedCard.id !== card.id)) return [3 /*break*/, 4];
                    if (!(react_native_1.Platform.OS === 'web')) return [3 /*break*/, 3];
                    // Note(fant): hack to wait for navigator.goBack to "finish" before we
                    // navigate to card details. Waiting for requestAnimationFrame isn't enough.
                    return [4 /*yield*/, (0, bluebird_1.delay)(100)];
                case 2:
                    // Note(fant): hack to wait for navigator.goBack to "finish" before we
                    // navigate to card details. Waiting for requestAnimationFrame isn't enough.
                    _a.sent();
                    navigator.replace('CardDetails', {
                        cardId: updatedCard.id,
                        channelSlug: channelSlug,
                        templateSlug: templateSlug,
                    });
                    return [3 /*break*/, 4];
                case 3:
                    navigator.goBack();
                    navigator.navigate('CardDetails', {
                        cardId: updatedCard.id,
                        channelSlug: channelSlug,
                        templateSlug: templateSlug,
                    });
                    _a.label = 4;
                case 4: return [2 /*return*/];
            }
        });
    }); }, [updateCard, card, navigateBack, navigator, channelSlug, templateSlug]);
    (0, navigation_1.useUnsavedChangesNavigationWarning)(formDirty && !submitting.current);
    if (!card || loading) {
        return react_1.default.createElement(components_1.MBLoadingIndicator, { fill: true });
    }
    if (!template) {
        return react_1.default.createElement(templateNotFound_1.MBTemplateNotFound, null);
    }
    return (react_1.default.createElement(viewTemplate_1.ViewTemplateProvider, { template: viewTemplate },
        react_1.default.createElement(components_2.MBCardForm, { card: card, cardDefinitionId: card.cardDefinitionId, blocks: blocks, fieldOverrides: fieldOverrides, restoreSavedData: restoreSavedData, onDirty: onDirty, onSubmit: handleSubmit, onSubmitFormButtonCallback: onSubmitFormButtonCallback })));
}
function MBCardUpdateForm(props) {
    return (react_1.default.createElement(channel_1.MBCardChannelProvider, { channelSlug: props.channelSlug },
        react_1.default.createElement(BatchContext_1.BatchEditContextProvider, null,
            react_1.default.createElement(CardUpdateForm, __assign({}, props)))));
}
