"use strict";
var __createBinding = (this && this.__createBinding) || (Object.create ? (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    var desc = Object.getOwnPropertyDescriptor(m, k);
    if (!desc || ("get" in desc ? !m.__esModule : desc.writable || desc.configurable)) {
      desc = { enumerable: true, get: function() { return m[k]; } };
    }
    Object.defineProperty(o, k2, desc);
}) : (function(o, m, k, k2) {
    if (k2 === undefined) k2 = k;
    o[k2] = m[k];
}));
var __setModuleDefault = (this && this.__setModuleDefault) || (Object.create ? (function(o, v) {
    Object.defineProperty(o, "default", { enumerable: true, value: v });
}) : function(o, v) {
    o["default"] = v;
});
var __importStar = (this && this.__importStar) || function (mod) {
    if (mod && mod.__esModule) return mod;
    var result = {};
    if (mod != null) for (var k in mod) if (k !== "default" && Object.prototype.hasOwnProperty.call(mod, k)) __createBinding(result, mod, k);
    __setModuleDefault(result, mod);
    return result;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useBulkUpdateState = void 0;
exports.BulkUpdateProvider = BulkUpdateProvider;
exports.useBulkUpdateStateUpdate = useBulkUpdateStateUpdate;
var lodash_1 = require("lodash");
var react_1 = __importStar(require("react"));
var mapCollectionCriteriaToState_1 = require("@shared/components/filter/state/mapCollectionCriteriaToState");
var context_1 = require("@shared/util/context");
var hooks_1 = require("@shared/util/hooks");
var _a = (0, context_1.createCtx)(), useBulkUpdateState = _a[0], BulkUpdateStateProvider = _a[1];
exports.useBulkUpdateState = useBulkUpdateState;
function BulkUpdateProvider(_a) {
    var children = _a.children, viewTemplate = _a.viewTemplate;
    var _b = (0, react_1.useState)(false), showBulkFilterUpdate = _b[0], setShowBulkFilterUpdate = _b[1];
    var _c = (0, react_1.useState)(false), showBulkFilterUpdateForm = _c[0], setShowBulkFilterUpdateForm = _c[1];
    var _d = (0, react_1.useState)((0, mapCollectionCriteriaToState_1.mapCollectionCriteriaToState)(null, [], [], [])), controlsState = _d[0], setControlsState = _d[1];
    var isDesktop = (0, hooks_1.useIsDesktop)();
    var _e = (0, react_1.useState)(0), totalCardCount = _e[0], setTotalCardCount = _e[1];
    var _f = (0, react_1.useState)(false), isLoading = _f[0], setIsLoading = _f[1];
    (0, react_1.useEffect)(function () {
        setShowBulkFilterUpdate(false);
    }, [viewTemplate === null || viewTemplate === void 0 ? void 0 : viewTemplate.id]);
    return (react_1.default.createElement(BulkUpdateStateProvider, { value: {
            showBulkFilterUpdate: isDesktop && showBulkFilterUpdate,
            setShowBulkFilterUpdate: setShowBulkFilterUpdate,
            showBulkFilterUpdateForm: isDesktop && showBulkFilterUpdateForm,
            setShowBulkFilterUpdateForm: setShowBulkFilterUpdateForm,
            totalCardCount: totalCardCount,
            setTotalCardCount: setTotalCardCount,
            isLoading: isLoading,
            setIsLoading: setIsLoading,
            controlsState: controlsState !== null && controlsState !== void 0 ? controlsState : null,
            setControlsState: setControlsState,
        } }, children));
}
function useBulkUpdateStateUpdate(_a) {
    var count = _a.count, loading = _a.loading, controlState = _a.controlState;
    useBulkUpdateCount(count);
    useBulkUpdateLoading(loading);
    useBulkUpdateControlState(controlState);
}
function useBulkUpdateCount(count) {
    var setTotalCardCount = useBulkUpdateState().setTotalCardCount;
    (0, react_1.useEffect)(function () {
        if ((0, lodash_1.isNil)(count)) {
            return;
        }
        setTotalCardCount(count);
    }, [count, setTotalCardCount]);
}
function useBulkUpdateLoading(loading) {
    var setIsLoading = useBulkUpdateState().setIsLoading;
    (0, react_1.useEffect)(function () {
        if ((0, lodash_1.isNil)(loading)) {
            return;
        }
        setIsLoading(loading);
    }, [loading, setIsLoading]);
}
function useBulkUpdateControlState(controlState) {
    var setControlsState = useBulkUpdateState().setControlsState;
    (0, react_1.useEffect)(function () {
        if ((0, lodash_1.isNil)(controlState)) {
            return;
        }
        setControlsState(controlState);
    }, [controlState, setControlsState]);
}
